import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import "./ProductPage.css";
import { Outlet } from "react-router-dom";
import { Helmet } from 'react-helmet';
import BrakeSideNav from "../../Components/BrakeSideNav/BrakeSideNav";

export default function HaganBrakeOutlet() {
  const [isVisible, setIsVisible] = useState(false);
  const bodyRef = useRef(document.body);


  
  useEffect(() => {
    // Add the "show" class after the component mounts to trigger the slide-in effect
    setIsVisible(true);

    // Remove the overflow-x: hidden when the animation completes
    return () => {
      bodyRef.current.style.overflowX = "auto";
    };


  }, []);

  useEffect(() => {
    // Hide the scrollbar on the x-axis when the slide-in animation is active
    if (isVisible) {
      bodyRef.current.style.overflowX = "hidden";
    }
  }, [isVisible]);


  return (
    <div className="TopParent">
     <Helmet>
        <title>Hagan Brake Pads</title>
      </Helmet>
          <Navbar LogoSrc={"/images/hagan_logo.svg"}  />
      <div className={`ProductPageParent ${isVisible ? "show" : ""}`}>
      {/* <Image Src={"/images/HomePage/HomePageBG.webp"} Width={"100%"} /> */}
      <div className="ProductPageBody">
      <div className="Page_Side_nav">
        <BrakeSideNav />
      </div>
      <div className="Pages_Content">
      <Outlet />
      </div>
      
        
      </div>
    </div>
    </div>
    
  );
}
