import Button from "../Button/Button";
import "./SideNav.css";
export default function BrakeSideNav() {
  return (
    <div className="ProductPageSideNav">
      <div className="ShowOnClick">
        <div className="ShowOnClick">
          {/* TRANSMISSION OIL TAB */}
          <div className="ProductSideNavLink2">
            <div className="DieselEngineOilTab">
              <div class="TabsSvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                >
                  <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                </svg>
              </div>
              <Button
                // RouteLink="/product/transmission_series"
                text="hagan brake pads"
                FontSize="1.8rem"
                FontWeight="100"
                TextTransform={"uppercase"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
