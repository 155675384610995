import React, { useEffect, useState } from "react";
import "./HaganBrakePadPage.css";
import Button from "../Button/Button";
import Text from "../Text/Text";
import Image from "../Image/Image";

export default function HaganBrakePadPage() {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add the "show" class after the component mounts to trigger the slide-in effect
    setIsVisible(true);
  }, []);


  const ProductProvides = [
    "Durable ceramic material ensures long-lasting performance.",
    "Consistent friction for reliable stopping power. ",
    "Quiet braking with reduced noise and vibrations.  ",
    "Low dust formula keeps wheels cleaner.  ",
    "Eco-friendly materials for sustainable use.",
  ];
  return (
    <div className={`HaganBrakePadPage  ${isVisible ? "show" : ""}`}>   
      <div className="Pao-0w20Parent d-flex justify-content-around">
        <div className="ProductDetails"> 
          <div className="ProductDetailsInner">
            <Button
              BGcolor={"white"}
              Radius={"50%"}
              Width={"50px"}
              Height={"50px"}
              text={`<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"/></svg>`}
              RouteLink="/"
            />
            <div className="ProductSpecificatons">
              <Text text="premium" Margin={"25px 0px 0px 0px"} TextTransform={"uppercase"} />
              <Text text="brake pads" FontSize={"4rem"} FontWeight={"600"} TextTransform={"uppercase"} />
              <Text text="HAGAN - Automatives" />
              <Text
                Margin={"25px 0px 20px 0px"}
                FontSize={"1.8rem"}
                text="HAGAN Ceramic Brake Pads are engineered to deliver unparalleled braking performance, combining advanced ceramic materials with precision engineering. Designed for drivers who demand both safety and efficiency, HAGAN Ceramic Brake Pads provide a seamless braking experience across a variety of driving conditions."
              />
              <Text
                FontSize={"1.8rem"}
                text="Whether navigating tight city streets or cruising on the highway, these brake pads ensure optimal performance, longevity, and comfort."
              />
            </div>
            <div className="ProductProvides">
              <Text
                text="HAGAN - Brake Pads Benefits"
                Margin={"25px 0px 25px 0px"}
                FontSize={"2.5rem"}
              />
              <ul>
              {ProductProvides.map((item) => (
                <li>
                  <Text text={item} FontSize={"1.5rem"} Color={"#949494"} />
                </li>
              ))}
            </ul>
            </div>
          </div>
        </div>
        <div className="ProductImage">
          <Image
            Src={"/images/HaganBrakePad/BrakePad-Img.png"}
            Width={"85%"}
          />
        </div>
      </div>
    </div>
  );
}
