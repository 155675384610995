import React, { useEffect, useState } from "react";
import "./Slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
// import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Pagination, Scrollbar, A11y, Mousewheel } from "swiper";

import Text from "../Text/Text";
import Image from "../Image/Image";
import Button from "../Button/Button";

SwiperCore.use([Pagination, Scrollbar, A11y, Mousewheel]); // Register Mousewheel module

// Learning From Cryptopedia Slider

const SwiperCarousel = ({ onSlideChange, currentSlide }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    // Set shouldAnimate to true after a short delay to trigger the animation
    const timer = setTimeout(() => {
      setShouldAnimate(true);
    }, 200); // Adjust delay as needed

    return () => clearTimeout(timer);
  }, []); // Run only once on component mount
  useEffect(() => {
    console.log("currentSlide", currentSlide);
  }, [currentSlide]); // Run only once on component mount
  return (
    <div className="HomePageSliderInnerSec">
      <Swiper
        onSlideChange={(swiper) => {
          // setCurrentSlide(swiper.activeIndex);
          onSlideChange(swiper.activeIndex);
        }}
        className=" CryptopediaSider"
        modules={[Pagination, Scrollbar, A11y]}
        direction="vertical"
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }} // Enable clickable pagination
        // onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        mousewheel={true} // Enable mouse wheel scrolling
        breakpoints={{
          // Responsive breakpoints
          150: {
            slidesPerView: 1, // Number of slides to show on mobile
            spaceBetween: 0, // Space between slides on mobile
          },
          767: {
            slidesPerView: 1, // Number of slides to show on mobile
            spaceBetween: 0, // Space between slides on mobile
          },
        }}
      >
        <SwiperSlide>
          <div className="HomePageBody">
            <div
              className={`PageHeaderText ${
                shouldAnimate && currentSlide == 0 ? "slide-in" : ""
              }`}
              onAnimationEnd={() => setShouldAnimate(false)}
            >
              <Image Src={"/images/HomePage/haganHeading.svg"} Width={"100%"} />
              <Text
                text={"a new era of engine protection"}
                TextTransform={"uppercase"}
                FontWeight={"200"}
                Margin={"0px 0px 0px 15px"}
              />
            </div>
            <div className="HaganBrakePadsLink">
            <div className="linkDiv">
            <Button
                RouteLink={"/hagan_brake_pads"}
                Src={"/images/HaganBrakePad/HaganBrakePadLink.png"}
              />
              <div className="MovingArrows">
                <Image Src={"/images/HaganBrakePad/BrakePad-Arrow.png"} />
              </div>
            </div>
              
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="HomePageSlide2">
            {/* <AboutNavbar LogoSrc={"/images/logo_dark.svg"}  /> */}
            <div className="AboutUsSlide d-flex justify-content-between">
              <div
                className={`AboutUsSlideLeft ${
                  shouldAnimate && currentSlide == 1 ? "slide-in" : ""
                }`}
                onAnimationEnd={() => setShouldAnimate(false)}
              >
                <Text
                  text={"HAGAN  <span>Lubricants</span>"}
                  Color={"#2B2B2B"}
                  FontWeight={"700"}
                  FontSize={"4.5rem"}
                />
                <Text
                  FontSize={"2.5rem"}
                  Margin={"20px 0px 0px 0px"}
                  Color={"black"}
                  text={
                    "Welcome to Hagan Group, a world-renowned leader in the automotive and lubricants industry, dedicated to providing unparalleled solutions for your car’s every need. Our diverse range of products and services is designed to elevate your driving experience, ensuring optimal performance and reliability."
                  }
                />
                <Text
                  text={"Who We Are"}
                  Color={"#2B2B2B"}
                  FontWeight={"700"}
                  FontSize={"3rem"}
                  Margin={"80px 0px 20px 0px"}
                />
                <Text
                  FontSize={"2rem"}
                  Color={"black"}
                  text={
                    "At Hagan Group and our associated companies, we take pride in our global presence and the trust we have earned from customers worldwide. With a legacy of excellence spanning several decades, we continue to push the boundaries of innovation to deliver top-quality products and services."
                  }
                />
              </div>
              <div className="AboutUsSlideRight">
                <div className="AboutUsSlideRightBG"></div>
                <div className="AboutusWhiteBlur"></div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="HomePageSlide3">
            <div className="BigText">
              <Text
                Width={"34%"}
                Color={"white"}
                FontWeight={"700"}
                FontSize={"6rem"}
                LineHeight={"1.2"}
                text="Outstanding Engine Protection  & <span> Fuel Economy Benefits. </span>"
              />
            </div>
            <div className="HaganOilCategory d-flex justify-content-between">
              <div
                className={`Category1 ${
                  shouldAnimate && currentSlide == 2 ? "slide-top" : ""
                }`}
              >
                <Text
                  text={"Hagan Lubricants:"}
                  Color={"white"}
                  Margin={"0px 0px 20px 0px"}
                  FontWeight={"500"}
                  FontSize={"2.5rem"}
                />
                <Text
                  text={
                    "Step into a world of engine efficiency with our premium Motor oil and Oil Additives. Our cutting-edge lubricants ensure your vehicle’s heart remains protected and operating at its best."
                  }
                  Color={"white"}
                />
              </div>
              <div
                className={`Category2 ${
                  shouldAnimate && currentSlide == 2 ? "slide-top" : ""
                }`}
              >
                <Text
                  text={"Hagan Automotives:"}
                  Color={"white"}
                  Margin={"0px 0px 20px 0px"}
                  FontWeight={"500"}
                  FontSize={"2.5rem"}
                />
                <Text
                  text={
                    "Discover a comprehensive range of automotive parts, meticulously crafted to meet the highest industry standards. From essential components to car care accessories."
                  }
                  Color={"white"}
                />
              </div>
              <div
                className={`Category3 ${
                  shouldAnimate && currentSlide == 2 ? "slide-top" : ""
                }`}
              >
                <Text
                  text={"Hagan AutoPRO:"}
                  Color={"white"}
                  Margin={"0px 0px 20px 0px"}
                  FontWeight={"500"}
                  FontSize={"2.5rem"}
                />
                <Text
                  text={
                    "Experience convenience like never before with Hagan AutoPRO, your one-stop destination for all your automotive needs. From expert maintenance services to top-notch repairs, our skilled professionals have got you covered."
                  }
                  Color={"white"}
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default function HomeSlider({ onSlideChange, currentSlide }) {
  return (
    <div className="HomePageSlider">
      <SwiperCarousel
        onSlideChange={onSlideChange}
        currentSlide={currentSlide}
      />
    </div>
  );
}
